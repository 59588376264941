import React from "react"
import AliceCarousel from "react-alice-carousel"

import cpj from "../../../assets/images/cpj.svg"
import voa from "../../../assets/images/voa.svg"
import meeye from "../../../assets/images/meeye.svg"
import ted from "../../../assets/images/ted.svg"
import guardian from "../../../assets/images/guardian.svg"
import unhcr from "../../../assets/images/unhcr.svg"
import ips from "../../../assets/images/ips.svg"
import i24 from "../../../assets/images/I24NEWS_logo.svg"

import "react-alice-carousel/lib/alice-carousel.css"
import "./style.scss"

const handleDragStart = e => e.preventDefault()

const items = [
  <div onDragStart={handleDragStart} role="presentation">
    <a
      target="_blank"
      rel="noreferrer"
      href="https://www.unhcr.org/news/videos/2021/8/610bd6ef4/fighting-fgm-ifrah-ahmed-is-a-champion-for-womens-rights.html"
    >
      <img src={unhcr} alt="UNHCR: The UN Refugee Agency" />
    </a>
  </div>,
  <div onDragStart={handleDragStart} role="presentation">
    <a
      target="_blank"
      rel="noreferrer"
      href="https://www.voanews.com/press-freedom/panel-highlights-challenges-women-journalists-report-shows-rise-threats"
    >
      <img src={voa} alt="VOA NEWS" />
    </a>
  </div>,
  <div onDragStart={handleDragStart} role="presentation">
    <a
      target="_blank"
      rel="noreferrer"
      href="https://www.middleeasteye.net/news/egypt-tiktok-influencers-women-prison-sentence-overturned"
    >
      <img src={meeye} alt="Middle Easy Eye" />
    </a>
  </div>,
  <div onDragStart={handleDragStart} role="presentation">
    <a
      target="_blank"
      rel="noreferrer"
      href="https://www.ted.com/talks/domtila_chesang_activism_on_female_genital_mutilation"
    >
      <img src={ted} alt="TED Talk" />
    </a>
  </div>,
  <div onDragStart={handleDragStart} role="presentation"> 
    <a
      target="_blank"
      rel="noreferrer"
      href="https://www.theguardian.com/society/2021/jun/23/sadia-hussein-the-fgm-survivor-who-is-saving-girls-from-the-knife"
    >
      <img src={guardian} alt="The Guardian" />
    </a>
  </div>,
  <div onDragStart={handleDragStart} role="presentation">
    <a
      target="_blank"
      rel="noreferrer"
      href="https://cpj.org/2021/03/journalist-reem-abdellatif-on-the-risks-facing-female-reporters-who-cover-the-gulf/"
    >
      <img src={cpj} alt="Committee to Protect Journalists" />
    </a>
  </div>,
  <div onDragStart={handleDragStart} role="presentation">
    <a
      target="_blank"
      rel="noreferrer"
      href="http://www.ipsnews.net/2021/04/online-violence-weaponization-deeply-rooted-misogyny-sexism-abuse-power/"
    >
      <img src={ips} alt="Inter Press Service" />
    </a>
  </div>,
  <div onDragStart={handleDragStart} role="presentation">
  <a
    target="_blank"
    rel="noreferrer"
    href="https://www.middleeasteye.net/discover/sudan-girls-comic-kawkab-awra-middle-east"
  >
    <img src={meeye} alt="Middle Easy Eye" />
  </a>
</div>,
  <div onDragStart={handleDragStart} role="presentation">
    <a
      target="_blank"
      rel="noreferrer"
      href="https://www.facebook.com/i24newsEN/videos/2565099790405997/"
    >
      <img src={i24} alt="i24 News" />
    </a>
  </div>,
  <div onDragStart={handleDragStart} role="presentation">
    <a
      target="_blank"
      rel="noreferrer"
      href="https://www.theguardian.com/global-development/2020/jun/16/coronavirus-millions-more-girls-risk-fgm"
    >
      <img src={guardian} alt="The Guardian" />
    </a>
  </div>,
  <div onDragStart={handleDragStart} role="presentation">
    <a
      target="_blank"
      rel="noreferrer"
      href="https://www.ted.com/talks/naimah_hassan_breaking_the_silence_on_fgm_jul_2020"
    >
      <img src={ted} alt="TED Talk" />
    </a>
  </div>,
  
]

const responsive = {
  0: { items: 2 },
  568: { items: 3 },
  1024: { items: 5 },
  1910: { items: 8 },
}

export const InMediaSection = () => {
  // const [activeIndex, setActiveIndex] = useState(0)
  // const syncActiveIndex = ({ item }) => setActiveIndex(item)

  return (
    <section className="in-media-wrapper">
      <header className="-header">
        <h2 className="-title">
          AWRA in media
        </h2>
      </header>
      <AliceCarousel
        infinite
        disableButtonsControls
        disableDotsControls
        // animationDuration="400"
        // autoPlayInterval="100"
        autoPlayStrategy="none"
        items={items}
        // activeIndex={activeIndex}
        responsive={responsive}
        // onSlideChanged={syncActiveIndex}
      /> 
        
    </section>
  )
}
