import * as React from "react"
import { graphql } from "gatsby"
// import { Link } from "gatsby"
// import { StaticImage } from "gatsby-plugin-image"

import {
  AboutSection,
  ArticlesSection,
  CampaignsSection,
  HeroSection,
  InMediaSection,
} from "../components/frontPage"
import Layout from "../components/layout"
import Seo from "../components/seo"

const IndexPage = ({data}) => {
  const { campaigns, posts} = data

  return (
    <Layout bodyClass="homepage">
      <Seo title="AWRA" />
      <HeroSection />
      <div className="container">
      <AboutSection />
      <InMediaSection />
      <CampaignsSection source={campaigns} />
      <ArticlesSection source={posts} />
      </div>
    </Layout>
  )
}

export default IndexPage

export const query = graphql`
  query indexPage {
    campaigns: allWpCauses(
      limit: 3
      filter: {languages: {nodes: {elemMatch: {name: {eq: "English"}}}}}
    ) {
      nodes {
        ...CausesPreview
      }
    }
    posts: allWpPost(
      limit: 3
      filter: {
        languages: { nodes: { elemMatch: { name: { eq: "English" } } } }
      }
      sort: { fields: date, order: DESC }
    ) {
      nodes {
        ...PostPreviewContent
      }
    }
  }
`
