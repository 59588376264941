import React from 'react'
import { MdArrowForward } from "@react-icons/all-files/md/MdArrowForward"
import { Link } from 'gatsby'

import PostPreview from '../../postPreview'
import './style.scss'

export const ArticlesSection = ({source}) => {
  return (
    <section className="articles-wrapper">
      <header className="-header">
        <h2 className="-title">
          Latest from AWRA
        </h2>
        <Link to="/blog" className="button is-outlined is-rounded is-link" >
          <span>All Articles</span>
          <MdArrowForward size="24" />
        </Link>
      </header>
      <div className="-container">
      {source ? 
      source.nodes.map((post, index) => {
        return (
        <div className="-post" key={index}>
          <PostPreview 
            key={index}
            post={post}
          />
        </div>
        )
      })
      : null}
      </div>
    </section>
  )
}
