import React from "react"
import { Link } from "gatsby"
import PostMeta from "../postMeta"
import PostCategories from "../postCategories"
import FeaturedImage from "../featuredImage"

import "./style.scss"

const PostPreview = ({ post, isLast }) => {
  return (
    <>
      <article
        className={`post-${post.databaseId} post type-post status-publish format-standard has-post-thumbnail hentry category-uncategorized`}
        id={`post-${post.databaseId}`}
      >
        
        <FeaturedImage image={post.featuredImage} />
        <div className="article">
          <header className="entry-header has-text-align-center">
            <div className="entry-header-inner section-inner medium">
              <PostCategories categories={post.categories} />

              <h3 className="entry-title heading-size-1">
                <Link
                  title={post.title}
                  to={post.uri}
                  dangerouslySetInnerHTML={{ __html: post.title }}
                />
              </h3>

              <PostMeta
                title={post.title}
                author={post.author}
                date={post.date}
              />
            </div>
          </header>

          <div className="post-inner thin ">
            <div
              className="entry-content"
              dangerouslySetInnerHTML={{
                __html: post.excerpt.replace(/&nbsp;/g, ""),
              }}
            ></div>
          </div>
        </div>
      </article>

      {/* {!isLast && (
        <hr
          key={post.postId + "-hr"}
          className="post-separator styled-separator is-style-wide section-inner"
          aria-hidden="true"
        />
      )} */}
    </>
  )
}

export default PostPreview
