import React from "react"
import { Link } from "gatsby"
import { MdArrowForward } from "@react-icons/all-files/md/MdArrowForward"

import CampaignPreview from "../../campaignPreview"
import "./style.scss"

export const CampaignsSection = ({ source }) => {
  return (
    <section className="campaigns-wrapper">
      <header className="-header">
        <h2 className="-title">Latest Campaigns</h2>
        <Link to="/campaigns" className="button is-outlined is-rounded is-link">
          <span>All Campaigns</span>
          <MdArrowForward size="24" />
        </Link>
      </header>
      <div className="-container">
        {source
          ? source.nodes.map((campaign, index) => {
              return <CampaignPreview key={index} post={campaign} />
            })
          : null}
      </div>
    </section>
  )
}
