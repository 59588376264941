import React from "react"
// import AuthorIcon from "../assets/svg/author.inline.svg"
import { Link } from "gatsby"
import { GatsbyImage ,getImage } from "gatsby-plugin-image"
// import DateIcon from "../assets/svg/date.inline.svg"
import {MdPerson} from '@react-icons/all-files/md/MdPerson'
import {MdToday} from '@react-icons/all-files/md/MdToday'

import './style.scss'
const PostMeta = ({ author, title, date }) => {
  author = author?.node
  const avatar = getImage(author?.avatar?.imageFile)

  let displayImage

  if(avatar) {
    displayImage = <GatsbyImage
    image={avatar}
    className="avatar"
    alt={author.name} />
  }
  
  return (
    <div className="post-meta-wrapper post-meta-single post-meta-single-top">
      <div className="avatar photo">
            {displayImage}
          </div>
      <ul className="post-meta">
        <li className="post-author meta-wrapper">
          <span className="meta-icon">
            <span className="screen-reader-text">Post author</span>
            {/* <AuthorIcon /> */}
            <MdPerson />
          </span>
          <span className="meta-text">
            <Link to={author.uri}>
              {author.firstName
                ? author.lastName
                  ? author.firstName + " " + author.lastName
                  : author.firstName
                : author.name}
            </Link>
          </span>
        </li>
        <li className="post-date meta-wrapper">
          <span className="meta-icon">
            <span className="screen-reader-text">Post date</span>
            {/* <DateIcon /> */}
            <MdToday />
          </span>
          <span className="meta-text">{date}</span>
        </li>
      </ul>
    </div>
  )
}

export default PostMeta