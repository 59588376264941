import React from "react"
import PropTypes from "prop-types"
import { renderToStaticMarkup as rtsm } from "react-dom/server"
import { MdKeyboardArrowRight } from "@react-icons/all-files/md/MdKeyboardArrowRight"

import "./style.scss"
import { Link } from "gatsby"

const listContent = [
  "It is a fact that women and girls are the most impacted when disasters or emergencies arise.",
  "African women deserve to be represented in decision-making circles.",
  "Survivor-led movements are essential in order for women and girls to thrive.",
  "African women, girls, and youth must truly believe that they deserve to thrive.",
  "Sustainable development requires community engagement in decision-making and ownership of the implementation processes.",
]

export const AboutSection = () => {
  const styles = {
    backgroundImage: `url("data:image/svg+xml; utf8, ${rtsm(
      <MdKeyboardArrowRight color="white" size="24" />
    ).replace(/"/g, "'")}")`,
  }

  return (
    <section className="about-section">
      {/* <MdKeyboardArrowRight /> */}
      <header className="-header">
        <div className="desc-wrapper">
          <h2 className="section-title">
            A group of African activists, thought leaders and entrepreneurs have
            banded together to form
          </h2>
          <h3 className="hero-text">
            The African Women Rights Advocates Movement
          </h3>
        </div>
        <div className="buttons-wrapper">
          <Link to="/volunteer" className="button is-rounded is-custom">Volunteer</Link>
          <Link to="/founders" className="button is-outlined is-rounded is-link">Meet the Founders</Link>
        </div>
      </header>
      <ul className="-body">
        {listContent
          ? listContent.map((content, i) => {
              return (
                <li key={i} style={styles}>
                  {content}
                </li>
              )
            })
          : null}
      </ul>
    </section>
  )
}

const myList = {
  listContent: PropTypes.array,
}

PropTypes.checkPropTypes(myList, listContent, "prop", "AboutSection")
