import React from "react"
import { Link } from "gatsby"
import FeaturedImage from "../featuredImage"

// import "./style.scss"

const CampaignPreview = ({ post, isLast }) => {
  return (
    <>
      <article
        className={`cause-${post.databaseId} cause type-cause status-publish format-standard has-post-thumbnail hentry category-uncategorized`}
        id={`cause-${post.databaseId}`}
      >
        <Link title={post.title} to={post.uri}>
          <FeaturedImage image={post.featuredImage} />
          <div className="article">
            <header className="entry-header has-text-align-center">
              <div className="entry-header-inner section-inner medium">
                <h3
                  dangerouslySetInnerHTML={{ __html: post.title }}
                  className="entry-title heading-size-1"
                ></h3>
              </div>
            </header>
          </div>
        </Link>
      </article>
    </>
  )
}

export default CampaignPreview
