import React, { useState } from "react"

import "./style.scss"


const Video = "https://wp.awra-group.org/wp-content/uploads/2024/05/AWRA.mp4"

export const HeroSection = () => {
  const [isMuted, setIsMuted] = useState(true)

  const toggleMute = () => {
    setIsMuted(!isMuted)
  }
  return (
    <section className="hero-section">
      <video id="heroVideo" src={Video} playinline="true" autoPlay loop muted={isMuted} />
      <div className="button-wrapper">
      <button onClick={toggleMute} className="mute" aria-label={isMuted ? "Unmute" : "Mute"}>
        <img src={isMuted ? "/images/volume_off.svg" : "/images/volume_up.svg"} />
      </button>
      </div>
    </section>
  )
}
